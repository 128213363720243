



































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import ErrorList from '@/components/ErrorList.vue';

import { Registration } from '@/models';
import { validate, ValidationError } from 'class-validator';
import { AuthService } from '@/lib/services';
import { headOrSelf, unwrapError } from '@/lib/helpers';
import TnfConfig from '@/config';
import SearchInput from '@/components/form/SearchInput.vue';
import {
  getCountryList,
  supportedUserLanguages,
  toLanguage,
} from '@/plugins/i18n';
import Logo from '@/components/Logo.vue';

@Component({
  components: {
    Logo,
    SearchInput,
    ErrorList,
  },
})
export default class Register extends Vue {
  registration: Registration = new Registration();
  acceptsTerms: boolean = false;
  showAcceptMessage: boolean = false;
  passwordMatch: boolean = true;
  errors: ValidationError[] = [];

  loading: boolean = false;
  countries: any[] = [];
  languages = supportedUserLanguages;

  created(): void {
    this.registration.country = 'NL';
    this.countries = getCountryList(this.$i18n);

    const browserLanguage = toLanguage(window.navigator.language.slice(0, 2));
    const selectedLanguage =
      this.languages.find((e) => e.value === browserLanguage) ||
      this.languages[0];
    this.registration.language = selectedLanguage.value;

    if ('email' in this.$route.query) {
      this.registration.email = headOrSelf(this.$route.query['email']) || '';
    }
    if ('promocode' in this.$route.query) {
      this.registration.promocode =
        headOrSelf(this.$route.query['promocode']) || '';
    }
  }

  mounted(): void {
    window.turnstile.ready(function () {
      window.turnstile.render('#turnstile-captcha', {
        sitekey: TnfConfig.turnstile,
        appearance: 'interaction-only',
      });
    });
  }

  async validate(): Promise<boolean> {
    this.errors = [];
    this.errors = await validate(this.registration);
    return this.errors.length === 0;
  }

  onPasswordChange(): void {
    this.passwordMatch =
      this.registration.password === '' ||
      this.registration.password === this.registration.passwordConfirm;
  }

  async onSubmit(): Promise<void> {
    try {
      this.loading = true;
      this.registration.captcha =
        window.turnstile.getResponse('#turnstile-captcha');
      if (!(await this.validate()) || !this.passwordMatch) return;
      if (!this.acceptsTerms) {
        this.showAcceptMessage = true;
        return;
      }

      await AuthService.register(this.registration);
      await this.$router.push('/setup');
    } catch (e) {
      this.$toaster.error('Could not register', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }
}
