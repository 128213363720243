





















































import Vue from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';

type InputOption = string | Record<string, string>;
type LabelFn = (option: InputOption) => string;
type FilterFn = (
  keyword: string,
  labelFn: LabelFn,
  options: InputOption[],
) => InputOption[];

function defaultFilter(
  keyword: string,
  labelFn: LabelFn,
  options: InputOption[],
): InputOption[] {
  const search = keyword ? keyword.toLowerCase() : '';
  return options.filter((e) => labelFn(e).toLowerCase().indexOf(search) >= 0);
}

@Component
export default class SearchInput extends Vue {
  @Model('change')
  selected: any;

  @Prop()
  optionLabel: string | LabelFn;

  @Prop({ default: () => defaultFilter })
  filter: FilterFn;

  @Prop() icon: string;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: '' })
  optionValue: string;

  @Prop({ default: () => [] })
  options: InputOption[];

  @Prop({ default: false, type: Boolean })
  nullable: boolean;

  @Prop({ default: true, type: Boolean })
  clearOnFocus: boolean;

  @Prop({ default: false, type: Boolean })
  defaultFirst: boolean;

  @Prop({ default: false, type: Boolean })
  translate: boolean;

  @Prop({ default: true, type: Boolean })
  limitHeight: boolean;

  @Prop({ default: () => ({}) })
  inputClass: Record<string, any>;

  @Prop({ default: 0 })
  itemLimit: number;

  $refs: {
    input: HTMLInputElement;
  };

  isOpen: boolean = false;
  keyword: string = '';
  highlightedPosition: number = 0;
  isActive: boolean = false;
  isFocused: boolean = false;

  mounted(): void {
    if (this.defaultFirst && this.options && !this.selectedOption) {
      this.keyword = this.optionText(this.options[0]);
      this.$emit('change', this.getEmitValue(this.options[0]));
    }
    if (this.selectedOption) {
      this.keyword = this.optionText(this.selectedOption);
      this.updateActive();
    }
  }

  @Watch('selected')
  updateKeyword(): void {
    this.keyword = this.selectedOption
      ? this.optionText(this.selectedOption)
      : '';
    this.updateActive();
  }

  getEmitValue(option: string | Record<string, string>): string {
    return this.optionValue.length ? option[this.optionValue] : option;
  }

  onInput(): void {
    this.isOpen = true;
    this.highlightedPosition = 0;
    this.updateActive();
  }

  updateActive(): void {
    this.isActive = this.activeKeyword.length > 0;
    this.updateFocus();
  }

  updateFocus(): void {
    this.isFocused =
      this.$refs.input === document.activeElement && this.isActive;
  }

  onFocus(): void {
    this.keyword = '';
    this.isOpen = true;
    this.updateFocus();
  }

  onBlur(): void {
    this.isOpen = false;
    this.updateFocus();
    if (
      this.selectedOption === undefined ||
      this.keyword !== this.optionText(this.selectedOption)
    ) {
      if (this.filteredOptions.length === 1) {
        this.$emit('change', this.getEmitValue(this.filteredOptions[0]));
      } else if (this.activeKeyword === '' && this.nullable) {
        this.keyword = '';
        this.$emit('change', null);
      } else {
        this.keyword = this.selectedOption
          ? this.optionText(this.selectedOption)
          : '';
      }
      this.updateActive();
    }
  }

  move(amount: number): void {
    if (!this.isOpen) {
      return;
    }

    this.highlightedPosition =
      (this.highlightedPosition + amount) % this.filteredOptions.length;
    if (this.highlightedPosition < 0) {
      this.highlightedPosition = this.filteredOptions.length - 1;
    }
  }

  select(): void {
    this.isOpen = false;
    const option = this.filteredOptions[this.highlightedPosition];
    this.keyword = this.optionText(option);
    this.$emit('change', this.getEmitValue(option));
    this.updateActive();
  }
  // TODO(joost 11-10-2021): I have no idea what the intended type of this option parameter is supposed to be
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  optionText(option: InputOption): string {
    let ret: string = option as string;

    if (this.optionLabel && option) {
      if (typeof this.optionLabel === 'string') {
        ret = option[this.optionLabel];
      } else if (typeof this.optionLabel === 'function') {
        ret = this.optionLabel(option);
      }
    }

    if (this.translate) {
      ret = this.$tc(ret);
    }

    return ret;
  }

  get selectedOption(): InputOption | undefined {
    if (this.optionValue.length) {
      return this.options.find(
        (option: InputOption) => option[this.optionValue] === this.selected,
      );
    }

    return this.selected;
  }

  get activeKeyword(): string {
    return this.keyword || '';
  }

  get filteredOptions(): InputOption[] {
    const opts = this.filter(this.activeKeyword, this.optionText, this.options);

    if (this.itemLimit) {
      return opts.slice(0, this.itemLimit);
    }
    return opts;
  }

  get labelClasses(): Record<string, boolean> {
    return {
      'tni-label--active': this.isActive,
      'tni-label--focused': this.isFocused,
    };
  }
}
