














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ValidationError } from 'class-validator';

@Component
export default class ErrorList extends Vue {
  @Prop({ required: true })
  errors: ValidationError[];
}
